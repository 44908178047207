import axiosInstance from "businessLogic/api";
import { ResetPass } from "businessLogic/model";
import * as _ from "lodash";
import { Store } from "redux";

const getAccessToken = (store: Store) => {
  const state = store.getState();
  return _.get(state, "auth.session.access_token");
};

const getRefreshToken = (store: Store) => {
  const state = store.getState();
  return _.get(state, "auth.session.refresh_token");
};

const refreshToken = (store: Store) => {
  const refreshToken = getRefreshToken(store);
  return axiosInstance
    .post(`/auth/jwt/token/refresh/`, { refresh: refreshToken })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

const login = (userInfo) => {
  return axiosInstance
    .post("api/auth/login/", userInfo)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);

      return Promise.reject(error.response ? error.response.data : error);
    });
};

const submit = (userInfo) => {
  return axiosInstance
    .post("api/auth/login/", userInfo)
    .then(() => {
      return true;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

const getCurrentUser = (token: string) => {
  return axiosInstance
    .get("/auth/user/current", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

const getPermissions = (token: string) => {
  return axiosInstance
    .get("/auth/user/permissions", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

const logout = (refreshToken) => {
  return axiosInstance
    .post("/auth/logout", { refresh_token: refreshToken })
    .then((response: any) => {
      localStorage.clear();

      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

const sendResetPassEmail = (email: ResetPass) => {
  return axiosInstance
    .post("/auth/token/reset_password", email)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};
const setNewPass = (passwordInfo: ResetPass) => {
  return axiosInstance
    .post(`/auth/token/set_new_password`, passwordInfo)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};
const verifyPassword = (tokenId: string) => {
  return axiosInstance
    .post(`/app/auth/verify-token/${tokenId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

export const AuthService = {
  login,
  submit,
  logout,
  getAccessToken,
  refreshToken,
  verifyPassword,
  setNewPass,
  sendResetPassEmail,
  getCurrentUser,
  getPermissions,
};
