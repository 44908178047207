import React, { useEffect, useState } from "react";
import Header from "components/UserHeader";
import styles from "./styles.module.scss";
import { getUserDetails } from "businessLogic/services/core";

const LoggedInPage = ({ Component }) => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (!user) {
      getUserDetails().then((data) => {
        setUser(data);
      });
    }
  }, [user, setUser]);

  return (
    <>
      <div className={styles.content}>
        <Header user={user} />
        <Component />
      </div>
    </>
  );
};
export default LoggedInPage;
