import {
  AuthActions,
  logoutFailure,
  logoutSuccess,
  setLoggedInUser,
  setLoginError,
  setSession,
  emailSent,
  setResetPassError,
  passwordReset,
} from "./actions";
import * as Sentry from "@sentry/browser";
import { Action } from "redux-actions";
import { all, put, takeEvery, call } from "redux-saga/effects";
import { UserDetails, ResetPass } from "businessLogic/model";
import { AuthService } from "businessLogic/services/auth";
import { push } from "connected-react-router";

function* loginAsync(action: Action<UserDetails>) {
  try {
    let userDetails = action.payload;
    let tokens = yield AuthService.login(userDetails);
    //let data = yield AuthService.getCurrentUser(tokens?.access);

    /**
     * @hack add user permissions to user
     
    if (!data.is_superuser) {
      data.user_permissions = yield AuthService.getPermissions(tokens?.access);
    }
    */

    yield put(
      setSession({
        auth_token: tokens.access,
        refresh_token: tokens.refresh,
        token_type: "",
        userid: "",
      })
    );
    //yield put(setLoggedInUser(data));

    yield put(push("/"));
  } catch (err) {
    console.error("Failed to login:", err);
    yield put(setLoginError(err.message || "Invalid login attempt"));
  }
}

function* logoutAsync(
  action: Action<{ refreshToken: string; preventRedirect: boolean }>
) {
  try {
    yield put(logoutSuccess());

    if (!action.payload.preventRedirect) {
      yield put(push("/"));
    }
  } catch (err) {
    yield put(logoutFailure());
    console.error("Failed to logout:", err.message);
  }
}

function* resetPassAsync(action: Action<ResetPass>) {
  try {
    const resetPassEmail = action.payload;
    yield AuthService.sendResetPassEmail(resetPassEmail);
    yield put(emailSent());
  } catch (err) {
    console.error("Failed to send email:", err);
    yield put(setResetPassError(err.message || "Failed to send email"));
  }
}

function* setNewPassAsync(action: Action<ResetPass>) {
  try {
    yield AuthService.setNewPass(action.payload);
    yield put(passwordReset());
  } catch (err) {
    console.error("Failed to set new password:", err);
    yield put(setResetPassError(err.message || "Failed to set new password"));
  }
}

function* logoutSuccessSaga() {
  yield call([Sentry, "configureScope"], (scope: Sentry.Scope) => {
    scope.setUser({});
  });
}

export function* AuthSagas() {
  return yield all([
    takeEvery(AuthActions.LOGIN, loginAsync),
    takeEvery(AuthActions.LOGOUT, logoutAsync),
    takeEvery(AuthActions.LOGOUT_SUCCESS, logoutSuccessSaga),
    takeEvery(AuthActions.RESET_PASSWORD, resetPassAsync),
    takeEvery(AuthActions.SET_NEW_PASSWORD, setNewPassAsync),
  ]);
}
