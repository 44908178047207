import axiosInstance from "../../api";

export const getUserDetails = () => {
  return axiosInstance
    .get("api/auth/current-user-email-view/")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};
