export const ROUTES = {
  PROJECTS: "/",
  PROJECT: "/project",
  SETTINGS: "/settings",
  USERS: "/users",
  PROFESSIONS: "/professions",
  PLANTYPES: "/plantypes",
  SPACETYPES: "/assets",
  PROPERTIES: "/properties",
  FLOORS: "/floors",
  SPACES: "/spaces",
  CRAFTS: "/crafts",
  TASKS: "/tasks",
  SUBTASKS: "/subtasks",
  PERMITS: "/permits",
  ASSIGNEDPERMITS: "/assigned-permits",
  PERMITSPERPROJECT: "/permits-per-project",
  INSPECTIONS: "/inspections",
  PLANS: "/plans",
  REPORTS: "/reports",

  LOGOUT: "/logout",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SET_NEW_PASSWORD: "/reset_password",
};
