import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router";
import withTracker from "components/common/GoogleAnalytics";
import LoadingOverlap from "components/LoadingOverlap";
import { ROUTES } from "businessLogic/routes.constants";
import LoggedInPage from "./LoggedInPage";
import {
  Permissions,
  USER_PERMISSIONS,
} from "businessLogic/helpers/Permissions";
import { Error403 } from "pages/ErrorPages";
import { UserDetails } from "../../businessLogic/model";

// App routes
const AsyncLoginPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Login" */ "pages/Auth/Login"
    )
);
const AsyncResetPassword = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "ResetPassword" */ "pages/Auth/ResetPassword"
    )
);
const AsyncActiveResetPassword = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "NewPassword" */ "pages/Auth/ResetPassword/SetNewPassword"
    )
);
const AsyncError404 = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Error404" */ "pages/ErrorPages"
    )
);
const AsyncProjectsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Projects"
    )
);

const AsyncProjectPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Project"
    )
);

const AsyncSettingsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Settings"
    )
);

const AsyncUsersPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Users"
    )
);

const AsyncProfessionsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Professions"
    )
);

const AsyncPlanTypesPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/PlanTypes"
    )
);

const AsyncSpaceTypesPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/SpaceTypes"
    )
);

const AsyncPropertiesPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Properties"
    )
);

const AsyncFloorsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Floors"
    )
);

const AsyncSpacesPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Spaces"
    )
);

const AsyncCraftsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Crafts"
    )
);

const AsyncTasksPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Tasks"
    )
);

const AsyncSubtasksPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Subtasks"
    )
);

const AsyncPermitsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Permits"
    )
);

const AsyncAsssignedPermitsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/ProjectPermits"
    )
);

const AsyncPermitsPerProjectPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/PermitsPerProject"
    )
);

const AsyncInspectionsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Inspections"
    )
);

const AsyncPlansPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Plans"
    )
);

const AsyncReportsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Reports"
    )
);

export interface RouterProps extends RouteComponentProps {
  isLoggedIn: boolean;
  user: UserDetails;
}

class Router extends Component<RouterProps, any> {
  loggedOutPage = (component) =>
    this.props.isLoggedIn
      ? () => {
          return <Redirect to={`/${this.props.location.search}`} />;
        }
      : component;

  ensureLoggedInAndPermission = (component, permission = null) => {
    if (!this.props.isLoggedIn) {
      return () => <Redirect to={"/login"} />;
    }

    if (
      permission &&
      !Permissions.haveUserPermission(this.props.user, permission)
    ) {
      return () => <Error403 />;
    }

    return () => <LoggedInPage Component={component} />;
  };

  renderGeneralRoutes = () => {
    return [
      // add general routes here
    ];
  };

  renderGuestUserRoutes = () => {
    return [
      <Route
        key={ROUTES.LOGIN}
        path={ROUTES.LOGIN}
        component={this.loggedOutPage(AsyncLoginPage)}
      />,
      <Route
        key={ROUTES.FORGOT_PASSWORD}
        path={ROUTES.FORGOT_PASSWORD}
        component={this.loggedOutPage(AsyncResetPassword)}
      />,
      <Route
        key={`${ROUTES.SET_NEW_PASSWORD}/:uid/:token`}
        path={`${ROUTES.SET_NEW_PASSWORD}/:uid/:token`}
        component={this.loggedOutPage(AsyncActiveResetPassword)}
      />,
    ];
  };

  renderAuthenticatedUserRoutes = () => {
    return [
      <Route
        key={"/"}
        path={"/"}
        exact
        component={this.ensureLoggedInAndPermission(AsyncProjectsPage)}
      />,
      <Route
        key={ROUTES.PROJECT}
        path={ROUTES.PROJECT}
        exact
        component={this.ensureLoggedInAndPermission(AsyncProjectPage)}
      />,
      <Route
        key={ROUTES.SETTINGS}
        path={ROUTES.SETTINGS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncSettingsPage)}
      />,
      <Route
        key={ROUTES.USERS}
        path={ROUTES.USERS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncUsersPage)}
      />,
      <Route
        key={ROUTES.PROFESSIONS}
        path={ROUTES.PROFESSIONS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncProfessionsPage)}
      />,
      <Route
        key={ROUTES.PLANTYPES}
        path={ROUTES.PLANTYPES}
        exact
        component={this.ensureLoggedInAndPermission(AsyncPlanTypesPage)}
      />,
      <Route
        key={ROUTES.SPACETYPES}
        path={ROUTES.SPACETYPES}
        exact
        component={this.ensureLoggedInAndPermission(AsyncSpaceTypesPage)}
      />,
      <Route
        key={ROUTES.PROPERTIES}
        path={ROUTES.PROPERTIES}
        exact
        component={this.ensureLoggedInAndPermission(AsyncPropertiesPage)}
      />,
      <Route
        key={ROUTES.FLOORS}
        path={ROUTES.FLOORS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncFloorsPage)}
      />,
      <Route
        key={ROUTES.SPACES}
        path={ROUTES.SPACES}
        exact
        component={this.ensureLoggedInAndPermission(AsyncSpacesPage)}
      />,
      <Route
        key={ROUTES.CRAFTS}
        path={ROUTES.CRAFTS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncCraftsPage)}
      />,
      <Route
        key={ROUTES.TASKS}
        path={ROUTES.TASKS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncTasksPage)}
      />,
      <Route
        key={ROUTES.SUBTASKS}
        path={ROUTES.SUBTASKS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncSubtasksPage)}
      />,
      <Route
        key={ROUTES.PERMITS}
        path={ROUTES.PERMITS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncPermitsPage)}
      />,
      <Route
        key={ROUTES.ASSIGNEDPERMITS}
        path={ROUTES.ASSIGNEDPERMITS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncAsssignedPermitsPage)}
      />,
      <Route
        key={ROUTES.PERMITSPERPROJECT}
        path={ROUTES.PERMITSPERPROJECT}
        exact
        component={this.ensureLoggedInAndPermission(AsyncPermitsPerProjectPage)}
      />,
      <Route
        key={ROUTES.INSPECTIONS}
        path={ROUTES.INSPECTIONS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncInspectionsPage)}
      />,
      <Route
        key={ROUTES.PLANS}
        path={ROUTES.PLANS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncPlansPage)}
      />,
      <Route
        key={ROUTES.REPORTS}
        path={ROUTES.REPORTS}
        exact
        component={this.ensureLoggedInAndPermission(AsyncReportsPage)}
      />,
    ];
  };

  render() {
    return (
      <React.Suspense fallback={<LoadingOverlap />}>
        <Switch>
          {this.renderGeneralRoutes()}
          {this.renderGuestUserRoutes()}
          {this.renderAuthenticatedUserRoutes()}
          <Route path="*" render={() => <AsyncError404 />} />
        </Switch>
      </React.Suspense>
    );
  }
}
const mapStateToProps = (state: any, props: any) => {
  return {
    isLoggedIn: !!_.get(state, "auth.session.access_token"),
    user: _.get(state, "auth.userDetails", null),
  };
};

export default withRouter(connect(mapStateToProps, {})(withTracker(Router)));
