import React, { useEffect } from "react";
import { ReactComponent as AiconLogo } from "assets/images/aicon.svg";
import { ReactComponent as JummaLogo } from "assets/images/jumma.svg";
import { logout } from "pages/Auth/redux/actions";
import styles from "./styles.module.scss";
import { ROUTES } from "businessLogic/routes.constants";
import { Link, RouteComponentProps } from "react-router-dom";
import UserBar from "components/UserBar/UserBar";

const Logo = (user?) => (
  <div className={styles.logoHeader}>
    <div className={styles.jummaLogo}>
      <AiconLogo />
      <Link className={styles.menuItem} to={ROUTES.PROJECTS}>
        <h4 className={styles.menuItem}>Projects</h4>
      </Link>
      <Link className={styles.menuItem} to={ROUTES.SETTINGS}>
        <h4 className={styles.menuItem}>Settings</h4>
      </Link>
    </div>
    <div className={styles.aiconLogo}>
      <UserBar user={user} />
      <JummaLogo />
    </div>
  </div>
);

export default Logo;
