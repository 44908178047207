import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createFilter } from "redux-persist-transform-filter";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import rootSaga from "./sagas";
import createInterceptor from "businessLogic/api/interceptor";

import { stateMigrations, MIGRATION_VERSION } from "./stateMigrations";

export const history = createBrowserHistory();

// Set redux-persist properties
const appReducer = createFilter("app", ["language", "sortDetails"]);
const authReducer = createFilter("auth", ["userDetails", "session"]);
const persistConfig = {
  key: "app",
  storage: storage,
  whitelist: ["app", "auth", "query", "database", "user"],
  transforms: [appReducer, authReducer],
  version: MIGRATION_VERSION,
  migrate: createMigrate(stateMigrations, { debug: false })
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(
  thunk,
  sagaMiddleware,
  routerMiddleware(history)
);

export default () => {
  const devtools: any = window["__REDUX_DEVTOOLS_EXTENSION__"]
    ? window["__REDUX_DEVTOOLS_EXTENSION__"]()
    : (f: any) => f;

  const store: any = createStore(
    persistedReducer,
    {},
    compose(middleware, devtools)
  );

  sagaMiddleware.run(rootSaga);
  createInterceptor(store);

  const persistor = persistStore(store);
  return { store, persistor };
};
