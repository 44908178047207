import { combineReducers } from "redux";
import appReducer from "components/App/redux/reducer";
import authReducer from "pages/Auth/redux/reducer";
import statusMessageReducer from "components/StatusMessage/redux/reducer";
import { connectRouter } from "connected-react-router";

const createRootReducer = (history) =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    statusMessage: statusMessageReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
