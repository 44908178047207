import { default as axiosInstance } from "./index";
import { AuthService } from "businessLogic/services/auth";
import { statusCodes } from "businessLogic/constants";
import { logoutSuccess, setSession } from "pages/Auth/redux/actions";
import { Store } from "redux";
import { push } from "connected-react-router";

let subscribers: any = [];
const loginPath = "/auth/login";

const servicesPaths = ["/iam", "/aws"];

const addTokenToAuthHeader = (token: any, config: any) => {
  if (token !== undefined) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onAccessTokenFetched = (access_token: any) => {
  subscribers = subscribers.filter((callback: any) => callback(access_token));
};

const addSubscriber = (access_token: any) => {
  subscribers.push(access_token);
};

export default function initializeInterceptors(store: Store) {
  initializeAllInterceptors(store, axiosInstance);
}

function initializeAllInterceptors(store: Store, instance) {
  instance.interceptors.request.use(
    (config) => {
      if (config.baseURL === process.env.REACT_APP_BASE_URL) {
        config.baseURL = servicesPaths.includes(config.url.substr(0, 4))
          ? config.baseURL
          : config.baseURL + `/${process.env.REACT_APP_API_VERSION}`;
      }

      const token = AuthService.getAccessToken(store);

      if (token) {
        const selectedAccountId = store.getState().user?.selectedAccountId;
        // If user has selected account (when has multiple membership)
        // add 'context_account_id' query param to all requests.
        // Applied only for requests targeted on packrest API
        if (
          selectedAccountId &&
          config.baseURL.includes(process.env.REACT_APP_BASE_URL)
        ) {
          const url = new URL(config.url, config.baseURL);
          url.searchParams.set("context_account_id", selectedAccountId);
          config.url = url.pathname + url.search;
        }

        return addTokenToAuthHeader(token, config);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (config) => config,
    (error) => {
      const { config, response } = error;
      const originalRequest = config;
      const unauthorized = (response) =>
        response &&
        response.status === statusCodes.UNAUTHORIZED &&
        !originalRequest.url.includes(loginPath);

      if (
        unauthorized(response) &&
        response.config.url === "/auth/jwt/token/refresh/" &&
        response.data.code === "token_not_valid"
      ) {
        localStorage.clear();
        store.dispatch(logoutSuccess());
        store.dispatch(push("/"));
        return;
      }

      if (unauthorized(response)) {
        AuthService.refreshToken(store)
          .then((response) => {
            const data = response;
            store.dispatch(
              setSession({
                auth_token: data.access,
                refresh_token: "",
                token_type: "",
                userid: "",
              })
            );
            onAccessTokenFetched(data.access);
            subscribers = [];
          })
          .catch((error) => {
            localStorage.clear();
            store.dispatch(logoutSuccess());
            store.dispatch(push("/"));
          });

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token: any) => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            resolve(axiosInstance(originalRequest));
          });
        });
        return retryOriginalRequest;
      }

      if (response && response.status === statusCodes.FORBIDDEN) {
        // TODO change error detail object format
        const detailsObj = response?.data?.authzDebug?.denyReasons;

        if (detailsObj) {
          // Make single string message from error details object
          error.response.data = new Error(Object.values(detailsObj).join("; "));
        }
      }

      return Promise.reject(error);
    }
  );
}
